select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
}

.container2 {
  
  position: relative;
  overflow: hidden;
  width: 100%;
 /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}



/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.form-check-input:checked {
 
  z-index: 30;
color: #fff;
border-color: #007984;
background-color: #007984;

}

.navitem:global(.active) {
  background: linear-gradient(to bottom, #007F8B 0%,#988EFA 100%);
}

.share{
 padding-top: 200px;
}

.videop{
   padding: 0% 8%; 
}

.badge {
  visibility: visible;
}

.radius{
  border-radius: 30px;
  border: 3px solid #febf01;
  vertical-align: middle
}

.borderG{
  border-left: solid  #797979;
}

.borderOrange{
  border: 2px solid rgba(254, 132, 2, 1);
}


.bar {
  width: 35px;
  height:12px;
}


.radius:hover{
  background: linear-gradient(to bottom, #007F8B 0%,#988EFA 100%);
}



.box{
  box-shadow: 3px 3px 15px rgb(0 0 0 / 30%);
}
.box2{
  box-shadow: 3px 3px 15px rgb(0 0 0 / 30%);
}
.box2:hover{
   box-shadow: 5px 5px 20px rgb(254 132 2 / 99%); 
}

.box1{
  box-shadow: 5px 5px 20px rgb(16 178 200 / 99%);
}

.box1:hover{
  box-shadow: 5px 5px 20px rgb(16 178 200 / 99%);
}


.fond_image img{

  -webkit-filter : saturate(0%); /* Chrome, Safari, Opera */
  filter : saturate(0%);
  
}

.fond_image img:hover{

  -webkit-filter : saturate(100%); /* Chrome, Safari, Opera */
  filter : saturate(100%);

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  
  
  }



}



.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.flash{
  position:fixed;
  z-index: 3;
  animation: slidein 2s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -4s);

  animation-iteration-count: 1;
  animation-fill-mode: both;
  }

@keyframes slidein {
  from {
   bottom: 10px;
   right:-100px;
    
  }

  to {
    bottom: 10px;
   right:10px;
    
    
  }
}

.img-rectangle {
  clip-path:ellipse(33% 50%);
  }

.list{
  
  transition: transform .5s;
  }

.list:hover{
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  }

  

  .slogan {
  font-size: 3.8em;
}

@media screen and (max-width: 992px) {
  .flash {
    visibility: hidden;
  }
  .bar {
    width: 70%;
   margin: auto;
   height:6px;
  }


  .share{
    padding-top: 0px;
   }
   
   .badge {
  visibility: hidden;
}
.btn1 {
    position: relative;
    padding:0.5em 0.5em ;
    overflow: hidden;
   
  }

  .slogan {
    font-size: 2.5em;
  }
}



@media (min-width: 992px) and (max-width: 1630px) {
  
  .btn1 {
    position: relative;
    padding:0.5em 0.5em ;
    overflow: hidden;
   
  }

  .slogan {
    font-size: 3em;
  }

}





* {
  box-sizing: border-box;
}





.btn1 {
  position: relative;
  padding:0.5em 0.5em ;
  overflow: hidden;
 
}




.btn1:before, .btn1:after {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: transparent;
}
.btn1:before {
  top: 0;
}
.btn1:after {
  bottom: 0;
}
.btn1:hover > * > *:before, .btn1:hover > * > *:after {
  transform: translate3d(0, 0, 0);
}
.btn1 > * > *:before, .btn1 > * > *:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 9;
  height: 4px;
  width: 100%;
  background-color: white;
}
.btn1 > * > *:before {
  bottom: 0;
  transform: translate3d(-25%, 0, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1 > * > *:after {
  top: 0;
  transform: translate3d(25%, 0, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1:hover > * > * > *:before, .btn1:hover > * > * > *:after {
  transform: translate3d(0, 0, 0);
}

/*  coté */

.btn1 > *:before, .btn1 > *:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: transparent;
}
.btn1 > *:before {
  left: 0;
}
.btn1 > *:after {
  right: 0;
}

.btn1 > * > * > *:before, .btn1 > * > * > *:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 4px;
  background-color: white;
}
.btn1 > * > * > *:before {
  /*  gauche */
  left: 0;
  transform: translate3d(0, 75%, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn1 > * > * > *:after {
  /*  DROITE */
  right: 0;
  transform: translate3d(0, -75%, 0);
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}